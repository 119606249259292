import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Box, TextField, InputAdornment, Button } from '@mui/material';
import { QuoteStatusEnum, QuoteTypeEnum } from 'utils/enums';
import RedTrash from 'assets/icons/Trash.svg';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { useModal } from 'react-hooks-use-modal';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import { CloseOutlined } from '@mui/icons-material';
import defaultAvatar from 'assets/avatar-default.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import moment from 'moment';
import useInvoiceList, { Props, ReceivedProps } from './hook';
import { ConfigProvider, DatePicker, Input } from 'antd';
import { Controller } from 'react-hook-form';
import invoiceValidation from 'utils/validation/registers/invoice';
import PlusIcon from 'assets/icons/white-plus.svg'
import jaJP from 'antd/es/locale/ja_JP';
import dayjs from 'dayjs';
import { NumberFormatBase, NumericFormat } from 'react-number-format';

const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));

statusOptions.unshift({
    value: 'all',
    name: 'すべて'
})

const LeftOutlinedCustom = styled(LeftOutlined)``;

const typeOptions: SelectSearchOption[] = [
    { value: 'all', name: 'すべて' },
    { value: 'system_development', name: 'システム開発、運用・保守' },
    { value: 'web_development', name: 'Web制作' },
    { value: 'event_business', name: 'イベント事業' },
    { value: 'recruitment', name: '人材紹介' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#377ACD',
        color: '#fff',
        fontWeight: 600,
        padding: '6px 8px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '0px 8px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const InvoiceListLayout: FC<Props> = ({
    invoiceList,
    handleSubmit,
    control,
    watch,
    register,
    getValues,
    setError,
    setValue,
    resetField,
    clearErrors,
    submitData,
    errors,
    defaultValue,
    setDefaultValue,
    onChangeDefault,
    arrItem,
    setArrItem,
    onAddArr,
    totalBeforeTax,
    totalAfterTax,
    totalTax,
    handleBlur,
    onDelete,
    handleValidateWhitespace,
    invoice,
    setIsCheckSend
}) => {
    const navigate = useNavigate();
    // const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
    //     'root',
    //     {
    //         preventScroll: true,
    //         //closeOnOverlayClick: false,
    //     },
    // );

    // useEffect(() => {
    // setValue(`content.${arrItem.length - 1}`, '')
    // setValue(`unit.${arrItem.length - 1}`, '')
    // setValue(`quantity.${arrItem.length - 1}`, '')
    // setValue(`price.${arrItem.length - 1}`, '')
    // setValue(`tax.${arrItem.length - 1}`, '')
    // }, [arrItem])
    return (
        <ConfigProvider locale={jaJP}>
            <form onSubmit={handleSubmit(submitData)} >
                <div className="container-worker d-flex flex-row">

                    <div className="worker-content d-flex flex flex-column">
                        <div className='flex flex-row justify-end' >
                            {invoice?.status && <div
                                className="text-overflow1"
                                style={{
                                    whiteSpace:
                                        'nowrap',
                                    color: '#727272',
                                    backgroundColor: '#1CB4031A',
                                    borderRadius:
                                        '15px',
                                    padding:
                                        '4px 9px',
                                }}
                            >
                                <p style={{ margin: '0px', lineHeight: '30px' }}>{invoice?.status === 'unpaid' ? '決済待ち' : '決済済み'} </p>
                            </div>}
                        </div>
                        <p className='text-[16px] font-bold'>会社の情報</p>
                        <div className='flex flex-row flex-wrap' style={{ gap: '10px' }} >
                            <Controller
                                name="companyName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            会社名
                                        </p>

                                        <StyledInput placeholder='会社名'
                                            {...register(
                                                'companyName',
                                                invoiceValidation().company(),
                                            )}
                                            {...field}
                                            onBlur={handleValidateWhitespace}
                                        />
                                        <br />
                                        {errors.companyName && (
                                            <span className="error">
                                                {errors.companyName.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                            <Controller
                                name="address"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            住所
                                        </p>
                                        <StyledInput placeholder='住所'
                                            {...register(
                                                'address',
                                                invoiceValidation().address(),
                                            )} {...field}
                                            onBlur={handleValidateWhitespace} />
                                        <br />
                                        {errors.address && (
                                            <span className="error">
                                                {errors.address.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                            <Controller
                                name="bankAccount"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            銀行口座
                                        </p>
                                        <StyledInput placeholder='銀行口座'
                                            {...register(
                                                'bankAccount',
                                                invoiceValidation().bankAccount(),
                                            )} {...field}
                                            onBlur={handleValidateWhitespace} />
                                        <br />
                                        {errors.bankAccount && (
                                            <span className="error">
                                                {errors.bankAccount.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />
                            <Controller
                                name="paymentDate"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            振込期限
                                        </p>
                                        <DatePicker
                                            {...register(
                                                'paymentDate',
                                                invoiceValidation().paymentDate(),
                                            )} {...field} format={'YYYY年MM月DD日'}
                                            onBlur={handleValidateWhitespace}
                                            value={field.value ? moment(field.value) : null}
                                            placeholder='yyyy年mm月dd日' style={{ width: '270px', height: '40px' }} />
                                        <br />
                                        {errors.paymentDate && (
                                            <span className="error">
                                                {errors.paymentDate.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />
                        </div>

                        <p className='text-[16px] font-bold mt-[20px]'>お客様の情報</p>
                        <div className='flex flex-row flex-wrap' style={{ gap: '10px' }} >
                            <Controller
                                name="customer"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            お客様
                                        </p>
                                        <StyledInput  {...register(
                                            'customer',
                                            invoiceValidation().customer(),
                                        )} placeholder='お客様' {...field}
                                            onBlur={handleValidateWhitespace} />
                                        <br />
                                        {errors.customer && (
                                            <span className="error">
                                                {errors.customer.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                            <Controller
                                name="address2"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            住所
                                        </p>
                                        <StyledInput {...register(
                                            'address2',
                                            invoiceValidation().address(),
                                        )} {...field} placeholder='住所'
                                            onBlur={handleValidateWhitespace} />
                                        <br />
                                        {errors.address2 && (
                                            <span className="error">
                                                {errors.address2.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                            <Controller
                                name="date"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            請求日
                                        </p>
                                        <DatePicker
                                            {...register(
                                                'date',
                                                invoiceValidation().date(),
                                            )} {...field} format={'YYYY年MM月DD日'}
                                            onBlur={handleValidateWhitespace}
                                            value={field.value ? moment(field.value) : null}
                                            placeholder='yyyy年mm月dd日' style={{ width: '270px', height: '40px' }} />
                                        <br />
                                        {errors.date && (
                                            <span className="error">
                                                {errors.date.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                            <Controller
                                name="callNum"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <div>
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            <span className="text-red mr-1">*</span>
                                            請求番号
                                        </p>
                                        <StyledInput placeholder='請求番号'
                                            style={{ background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }}
                                            {...register(
                                                'callNum',
                                                invoiceValidation().callNum(),
                                            )} {...field}
                                            onBlur={handleValidateWhitespace}
                                            disabled />
                                        <br />
                                        {errors.callNum && (
                                            <span className="error">
                                                {errors.callNum.message?.toString()}
                                            </span>
                                        )}
                                    </div>

                                )}
                            />

                        </div>

                        <div className="table-workers d-flex flex-column mt-[45px]">
                            <div className="table-content d-flex flex-column">
                                <TableContainer table-layout={'auto'}>
                                    <Table
                                        sx={{ minWidth: 900 }}
                                        aria-label="customized table"
                                    >
                                        <TableHead style={{ background: '#377ACD', color: '#fff' }}>
                                            <TableRow>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '5%', whiteSpace: 'nowrap' }}
                                                >
                                                    <div>番号</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '25%' }}
                                                >
                                                    <div>内容</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div>数量</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '10%' }}
                                                >
                                                    <div>単位</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '12%' }}
                                                >
                                                    <div>単価</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '12%' }}
                                                >
                                                    <div>金額</div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '12%', textAlign: 'center' }}
                                                >
                                                    <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }} >金額（税込）</div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '5%', padding: '0px' }}
                                                >
                                                    <div>操作</div>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {arrItem.map((row, ind) => {
                                                if (ind < arrItem.length) {
                                                    return (
                                                        <StyledTableRow key={row}>
                                                            <StyledTableCell
                                                                className="!text-left"
                                                                component="th"
                                                                scope="row"
                                                                style={{ width: '5%' }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {row}
                                                                    </span>
                                                                </div>
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                className="!text-left"
                                                                style={{ width: '25%' }}
                                                            >
                                                                <Controller
                                                                    name={`content.${ind}`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <StyledDefaultInput
                                                                            {...register(
                                                                                `content.${ind}`
                                                                            )}
                                                                            {...field} />

                                                                    )}
                                                                />

                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                className="!text-left"
                                                                style={{ width: '10%' }}
                                                            >
                                                                <div>
                                                                    <Controller
                                                                        name={`quantity.${ind}`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            // <div
                                                                            //     className="text-overflow1"
                                                                            //     style={{
                                                                            //         whiteSpace:
                                                                            //             'nowrap',
                                                                            //     }}
                                                                            // >

                                                                            //     {/* <StyledDefaultInput type='number'
                                                                            //             style={{ marginTop: '5px' }}
                                                                            //             onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                                                            //             onWheel={(e) => e.preventDefault()}
                                                                            //             {...register(
                                                                            //                 `quantity.${ind}`
                                                                            //             )} {...field} onBlur={handleBlur} /> */}
                                                                            // </div>
                                                                            <NumericFormat
                                                                                customInput={StyledDefaultInput}
                                                                                thousandSeparator=","
                                                                                decimalSeparator="."
                                                                                onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                                                                onWheel={(e) => e.preventDefault()}
                                                                                {...register(
                                                                                    `quantity.${ind}`
                                                                                )} onBlur={handleBlur}
                                                                                value={field.value}
                                                                                style={{ textAlign: 'right' }}
                                                                                onValueChange={(values) => {
                                                                                    field.onChange(values.value);
                                                                                    setValue(`quantity.${ind}`, values.value)
                                                                                }}
                                                                                name={field.name}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                className="!text-left"
                                                                style={{ width: '10%' }}
                                                            >
                                                                <Controller
                                                                    name={`unit.${ind}`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <StyledDefaultInput maxLength={3}
                                                                            style={{ textAlign: 'center' }}
                                                                            {...register(
                                                                                `unit.${ind}`
                                                                            )} {...field} />

                                                                    )}
                                                                />
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                align="center"
                                                                style={{ width: '12%' }}
                                                            >
                                                                <Controller
                                                                    name={`price.${ind}`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <NumericFormat
                                                                            customInput={StyledDefaultInput}
                                                                            thousandSeparator=","
                                                                            decimalSeparator="."
                                                                            style={{ textAlign: 'right' }}
                                                                            // decimalScale={2}
                                                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                                                            onWheel={(e) => e.preventDefault()}
                                                                            prefix={'￥'}
                                                                            {...register(
                                                                                `price.${ind}`
                                                                            )} onBlur={handleBlur}
                                                                            value={field.value}
                                                                            onValueChange={(values) => {
                                                                                field.onChange(values.value);
                                                                                setValue(`price.${ind}`, values.value)
                                                                            }}
                                                                            name={field.name}
                                                                        // ref={field.ref}
                                                                        />

                                                                    )}
                                                                />
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                align="center"
                                                                style={{ width: '12%' }}
                                                            >
                                                                <NumericFormat
                                                                    customInput={StyledDefaultInput}
                                                                    thousandSeparator=","
                                                                    decimalSeparator="."
                                                                    prefix={'￥'}
                                                                    name='amount_money'
                                                                    style={{ textAlign: 'right', background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }}
                                                                    value={(Number(getValues(`price.${ind}`)) * Number(getValues(`quantity.${ind}`))) || '0'}
                                                                    disabled

                                                                />

                                                            </StyledTableCell>
                                                            {/* <StyledTableCell
                                                                align="center"
                                                                style={{ width: '10%' }}
                                                            >
                                                                <div>
                                                                    <Controller
                                                                        name={`tax.${ind}`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <div>
                                                                                <div
                                                                                    className="text-overflow1"
                                                                                    style={{
                                                                                        whiteSpace:
                                                                                            'nowrap',
                                                                                    }}
                                                                                >
                                                                                    <StyledDefaultInput suffix="%" type='number'
                                                                                        style={{ height: '38px', padding: '0px', paddingLeft: '0px' }}
                                                                                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                                                                        onWheel={(e) => e.preventDefault()}
                                                                                        {...register(
                                                                                            `tax.${ind}`
                                                                                        )} {...field} onBlur={handleBlur} />
                                                                                </div>
                                                                            </div>

                                                                        )}
                                                                    />
                                                                </div>
                                                            </StyledTableCell> */}
                                                            <StyledTableCell
                                                                align="center"
                                                                style={{ width: '12%' }}
                                                            >
                                                                <NumericFormat
                                                                    customInput={StyledDefaultInput}
                                                                    thousandSeparator=","
                                                                    decimalSeparator="."
                                                                    prefix={'￥'}
                                                                    disabled
                                                                    name='amount_money_tax'
                                                                    style={{ textAlign: 'right', background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }}
                                                                    value={(Number(getValues(`price.${ind}`)) * Number(getValues(`quantity.${ind}`)) +
                                                                        Number(getValues(`price.${ind}`)) *
                                                                        Number(getValues(`quantity.${ind}`)) * Number(getValues(`tax.${ind}`)) / 100) || '0'}
                                                                />

                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align="center"
                                                                style={{ width: '5%', paddingLeft: '0px' }}
                                                            >
                                                                <div style={{ borderLeft: '1px solid #D9D9D9', height: '40px' }}
                                                                    className="flex justify-center items-center">
                                                                    <Box
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            width: 24,
                                                                            height: 24,
                                                                            margin: '0px',
                                                                            marginLeft: '12px'
                                                                        }}
                                                                        onClick={() => {
                                                                            onDelete(ind)
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                RedTrash
                                                                            }
                                                                            alt="red trash icon"
                                                                        />
                                                                    </Box>
                                                                </div>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                }
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                            <div className='flex flex-row justify-between'>
                                <div />
                                <div />
                                <Button className='w-[114px] h-[38px] text-white bg-[#215493]'
                                    style={{ borderRadius: '8px', background: '#215493', gap: '8px', marginTop: '10px' }} onClick={onAddArr} >
                                    <img src={PlusIcon} alt='plus' height={16} width={16} /> 追加</Button>
                                <div className='flex flex-col items-end text-[14px] p-[5px]' >
                                    <div className='flex flex-col bg-[#E8F2FF] w-[300px] p-[5px]' style={{ borderRadius: '8px' }}>
                                        <div className='flex flex-row justify-between h-[32px]'>
                                            <p style={{ color: '#0000008C' }} >小計</p>
                                            <p>¥{totalBeforeTax.toLocaleString('en-US')}</p>
                                        </div>
                                        <div className='flex flex-row justify-between h-[32px]'>
                                            <p style={{ color: '#0000008C' }}>割引</p>
                                            <p>¥0</p>
                                        </div>
                                        <div className='flex flex-row justify-between h-[32px]'>
                                            <p style={{ color: '#0000008C' }}>消費税（10％）</p>
                                            <p>¥{totalTax.toLocaleString('en-US')}</p>
                                        </div>
                                        <div className='flex flex-row justify-between h-[32px]'>
                                            <p style={{ color: '#0000008C' }}>合計</p>
                                            <p>¥{totalAfterTax.toLocaleString('en-US')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="container-worker d-flex flex-row">
                    <div className="worker-content d-flex flex-column">

                    </div>

                </div> */}
                <div className='bg-[#fff] flex flex-row justify-end' style={{ padding: '16px 24px 16px 24px' }} >
                    <div
                        className="d-flex flex-row text-lg"
                        style={{
                            marginTop: '14px',
                        }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row w-[140px]"
                            style={{ width: '140px', borderRadius: '0px', border: '1px solid #D9D9D9', color: '#000000D9' }}
                            type="button"
                            onClick={() => navigate(-1)}
                        >
                            キャンセル
                        </button>

                        <button
                            className="modal-create-user-button2 d-flex flex-row mx-[15px] w-[140px]"
                            disabled={invoice?.status === 'paid'}
                            style={{
                                width: '140px', borderRadius: '0px',
                                background: `${invoice?.status === 'paid' && '#CCCCCC'}`
                                // background: `${getValues('companyName') && getValues('address') && getValues('bankAccount') && getValues('paymentDate')
                                // && getValues('date') && getValues('customer') && getValues('address2') && getValues('callNum') ? '' : '#CCCCCC'}`
                            }}
                            // disabled={getValues('companyName') && getValues('address') && getValues('bankAccount') && getValues('paymentDate')
                            // && getValues('date') && getValues('customer') && getValues('address2') && getValues('callNum') ? false : true}
                            type="submit"
                        >
                            保存
                        </button>

                        <button
                            className="modal-create-user-button2 !bg-[#02A509] w-[140px] d-flex flex-row "
                            type="submit"
                            style={{ width: '140px', borderRadius: '0px' }}
                            onClick={() => {
                                setIsCheckSend(true);
                            }}
                        >
                            LINE送信
                        </button>
                    </div>
                </div>
            </form>
        </ConfigProvider>
    );
};

const InvoiceList: FC<ReceivedProps> = (props) => {
    return <InvoiceListLayout {...useInvoiceList(props)} />;
};

const StyledInput = styled(Input)({
    padding: '8px 12px 8px 12px',
    width: '270px'
})

const StyledDefaultInput = styled(Input)({
    padding: '8px 12px 8px 12px',
    // border: 'none',
    height: '31px',
    border: '1px solid #D9D9D9',
    width: '100%',
    borderRadius: '8px'
})

export default InvoiceList;
